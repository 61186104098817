import { type PrinterType } from '~/features/printer/store/types'

export const initialState_warehouse_labels: initialStateType_warehouse_labels =
  {
    printerConfigured: false,
    pageSize: 'DefaultSize',
    pageSizeOptions: [
      'DefaultSize',
      'A4',
      'A5',
      'Avery100x30',
      'Avery100x50',
      'Avery100x75',
      'Avery150x100',
      'Avery40x30',
      'Avery89x36',
      'Avery50x30x2Col'
    ],
    componentLabel: {
      sku: '',
      description: '',
      orderNo: '',
      sizeAndWeight: '',
      barcode: ''
    },
    storeCollectionLabel: {
      orderNumber: '',
      verifiedDate: '',
      customer: '',
      packages: 1
    },
    retailLabel: {
      region: 'RSA',
      store: {
        id: 9,
        regionCode: 'GER',
        region: 'Germany',
        language: 'English'
      },
      pageSize: 'Avery50x30x2Col'
    },
    qty: 1, // To print
    store_id: 0,

    warehouse: null,
    selectedWarehouse: null,
    loading: {
      print_label: false,
      create_label: false,
      productInfo: false,
      getBarcode: false,
      getPrices: false,
      getProductUrlAndDescription: false,
      getRetailLabels: false
    },
    modals: {
      component_label: false,
      collection_label: false
    },
    label: null,
    pagination: {
      descending: true,
      filter: '',
      page: 1,
      rowsNumber: 0,
      rowsPerPage: 20,
      sortBy: 'order_id'
    }
  }

type initialStateType_warehouse_labels = {
  printerConfigured: boolean
  pageSize: string
  pageSizeOptions: string[]
  componentLabel: {
    sku: string
    description: string
    orderNo?: string
    sizeAndWeight: string
    barcode: string
  }
  storeCollectionLabel: {
    orderNumber: string
    verifiedDate: string
    customer: string
    packages: number
  }
  retailLabel: {
    region: string
    store: {
      id: number
      regionCode: string
      region: string
      language: string
    }
    pageSize: string
  }
  qty: number
  store_id: number
  warehouse: {
    bins: unknown[]
  } | null
  selectedWarehouse: {
    id: string
    description: string
  } | null
  loading: loadingTypes
  modals: {
    component_label: boolean
    collection_label: boolean
  }
  label: {
    contents: string
    file_type: PrinterType['file_type']
    file_name: PrinterType['file_name']
  } | null
  pagination: {
    descending: boolean
    filter?: string | null
    page: number
    rowsNumber?: number
    rowsPerPage: number
    sortBy: string
  }
}

export type loadingTypes = {
  print_label: boolean
  create_label: boolean
  productInfo: boolean
  getBarcode: boolean
  getPrices: boolean
  getProductUrlAndDescription: boolean
  getRetailLabels: boolean
}
